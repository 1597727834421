import { Fragment } from 'react'
import PageContainer from "./react_components/PageContainer/PageContainer";
import Navigation from "./react_components/Navigation/Navigation";
import Footer from './react_components/Footer/Footer';
function App() {
  return (
    <Fragment>
      <Navigation />
      <div className="container">
        <PageContainer />
      </div>
      <Footer />
    </Fragment>
  );
}

export default App;
