const Navigation = () => {

    return (
        <header className="header">
            <nav className="nav container">
                <svg className="nav__logo">

                    <use xlinkHref="global/xdsj/logo.svg#logo"></use>

                </svg>
                {/* <ul className="nav__list">
                    <li className="nav__item"><a className="nav__link ">首页</a></li>
                    <li className="nav__item"><a className="nav__link ">案例</a></li>
                    <li className="nav__item"><a className="nav__link ">联系我们</a></li>
                </ul> */}
            </nav>
        </header>
    )

}
export default Navigation;