import CaseVideo from "../../../Item_components/CaseVideo";
const CaseVideoList = (props) => {
    console.log(props.caseVideo_info);

    const VideoList = props.caseVideo_info.map((item) =>
        <CaseVideo
            title={item.title}
            cover={item.cover}
            video={item.video}
            key={item.key} />
    )

    return (


        <section className="mb-md">
            {VideoList}
        </section>
    )

}
export default CaseVideoList;