export const service_info = [
    {
        title: `述标演示制作`,
        subtitle: `满足重大项目招标时视频述标要求，对比传统PPT演示可以在满足打分要求的同时更直观的展示产品特点及产品性能，为现场评审留下更深刻的印象。`,
        icon: 'toubiao',
        key: Math.random()
    },
    {
        title: `视效创意广告`,
        subtitle: `在产品还未正式投产处于概念阶段或作为全新产品发布时，为使客户更加清晰直观的了解产品特性及产品卖点，使用CG动画技术使产品功能具象化。`,
        icon: 'chanpin',
        key: Math.random()
    },
    {
        title: `产品互动演示`,
        subtitle: `将产品多元化的信息内容嵌入WEB渠道，在网页上生成可交互的3D模型演示方案，提供全方位的查看与互动，展示产品的不同配置与两亮点`,
        icon: 'hudong',
        key: Math.random()
    }
]

export const caseVideo_info = [
    {
        title: `软件中台`,
        cover: `./demoVideo/cover/数据中台_cover.png`,
        video: `./demoVideo/数据中台.mp4`,
        key: Math.random()
    },
    {
        title: `无人驾驶`,
        cover: `./demoVideo/cover/无人驾驶_cover.png`,
        video: `./demoVideo/无人驾驶.mp4`,
        key: Math.random()
    },
    {
        title: `3C电商`,
        cover: `./demoVideo/cover/扫地机器人_cover.png`,
        video: `./demoVideo/扫地机器人.mp4`,
        key: Math.random()
    },
    {
        title: `智能芯片`,
        cover: `./demoVideo/cover/芯片_cover.png`,
        video: `./demoVideo/芯片.mp4`,
        key: Math.random()
    },
]


export const caseImg_info = [
    {
        isBanner: true,
        title: `软件中台`,
        cover: `./demoImg/cover/01.png`,
        key: Math.random()
    },
    {
        isBanner: false,
        title: `无人驾驶`,
        cover: `./demoImg/cover/02.png`,
        key: Math.random()
    },
    {
        isBanner: false,
        title: `智能芯片`,
        cover: `./demoImg/cover/03.png`,
        key: Math.random()
    },
    {
        isBanner: false,
        title: `软件中台`,
        cover: `./demoImg/cover/04.png`,
        key: Math.random()
    },
    {
        isBanner: false,
        title: `软件中台`,
        cover: `./demoImg/cover/05.png`,
        key: Math.random()
    },

]