import Label from './Label';

const Banner = () => {
    return (

        <div className="banner ">

            <div className="banner__obj-container container mb-lg">
                <video className="" autoPlay muted loop>
                    <source src="./demoVideo/banner.mp4" type="video/mp4" />
                </video>
                <img src="./demoVideo/cover/banner.png" />
            </div>

            <div className="banner__messsage-container container">
                <h1 className="heading-hg mb-md  ">数字影像与互动营销</h1>
                <h2 className="heading-2 ">相对视觉为您提供互动演示、视频演示材料，解决面对面营销信息部署难题。</h2>

                {/* <div className="btn btn__standard">与我们取得联系</div> */}
            </div>

            <div className="banner__label container mb-md">
                <Label>实时互动</Label>
                <Label>效果图</Label>
                <Label>展会</Label>
                <Label>述标视频</Label>
                <Label>电商</Label>
                <Label>新零售</Label>
                <Label>发布</Label>
                <Label>动画渲染</Label>
            </div>

        </div>

    )
}
export default Banner;