import { useRef, useState } from "react";


const CaseVideo = (props) => {

    console.log(props);

    const videoRef = useRef();
    const videoButtonRef = useRef();

    const [loadVideo, setLoadVideo] = useState(false);
    const [videoPaused, setVideoPaused] = useState(false);

    const coverClass = loadVideo ? "isNotDisplayed" : "caseVideo__item-cover"
    const videoClass = loadVideo ? "caseVideo__item-video" : "isNotDisplayed"
    const playButtonIcon = loadVideo ? "global/xdsj/icon.svg#fullScreen" : "global/xdsj/icon.svg#play"
    const videoButtonClass = videoPaused ? "caseVideo__buttons caseVideo__buttons-onPaused" : "caseVideo__buttons"


    const videoTitleInHandle = (event) => {
        videoButtonRef.current.className = "caseVideo__buttons caseVideo__buttons-onPaused";
    }


    const videoTitleClickHandle = (event) => {
        setVideoPaused(videoRef.current.paused)
    }


    const videoTitleOutHandle = (event) => {
        setVideoPaused(videoRef.current.paused)
        if (!videoPaused) { videoButtonRef.current.className = "caseVideo__buttons" }
    }


    const loadVideoHandler = (event) => {
        if (!loadVideo) {
            setLoadVideo(true)
            videoRef.current.play()
        }
        if (loadVideo) {
            videoRef.current.webkitRequestFullScreen();
        }
    }

    return (
        <div className="container caseVideo">

            <div className="caseVideo__item">

                <div className={coverClass} ><img src={props.cover} alt={props.title} /></div>
                <video className={videoClass} loop controls ref={videoRef} onMouseEnter={videoTitleInHandle} onClick={videoTitleClickHandle} onMouseLeave={videoTitleOutHandle} >
                    <source src={props.video} type="video/mp4" />
                </video>

                <div className={videoButtonClass} ref={videoButtonRef}>
                    <div className="caseVideo__title heading-1">{props.title}</div>
                    <div className="caseVideo__buttons-play" onClick={loadVideoHandler}>
                        <svg className="">
                            <use xlinkHref={playButtonIcon}></use>
                        </svg>
                    </div>
                </div>

            </div>
        </div >

    )

}
export default CaseVideo;