import { Fragment } from "react"
import { service_info } from "./info_temp/home_info.js"
import { caseVideo_info } from "./info_temp/home_info.js"
import { caseImg_info } from "./info_temp/home_info.js"


import ServicesList from "./Home_Components/ServicesList"
import Banner from "../../Item_components/Banner"
import Clients from "../../Item_components/Clients"
import CaseVideoList from "./Home_Components/CaseVideoList"
import CaseImgList from "./Home_Components/CaseImgList"


const Home = () => {

    return (
        <Fragment>

            <section>
                <Banner />
            </section>

            <section className="mb-hg" >
                <ServicesList service_info={service_info} />
            </section>

            <section className="mb-hg">
                <CaseVideoList caseVideo_info={caseVideo_info} />
            </section>

            <section className="mb-hg">
                <CaseImgList caseImg_info={caseImg_info} />
            </section>

            <section className="mb-hg">
                <Clients />
            </section>

        </Fragment>)
}
export default Home