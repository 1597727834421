
import Home from "./Pages/Home"

const PageContainer = () => {
    return (
        <> <Home />

        </>
    )

}
export default PageContainer