const Clients = () => {
    return (
        <div className="centered container with-80">
            <h2 className="heading-1 mb-lg ">合作伙伴及客户</h2>
            <div className="clients__logo-list centered container">
                <div className="clients__logo">
                    <img src="./clients/logo/01.png" alt="test" />
                </div>

                <div className="clients__logo">
                    <img src="./clients/logo/02.png" alt="test" />
                </div>
                <div className="clients__logo">
                    <img src="./clients/logo/03.png" alt="test" />
                </div>
                <div className="clients__logo">
                    <img src="./clients/logo/04.png" alt="test" />
                </div>
                <div className="clients__logo">
                    <img src="./clients/logo/05.png" alt="test" />
                </div>
                <div className="clients__logo">
                    <img src="./clients/logo/06.png" alt="test" />
                </div>
                <div className="clients__logo">
                    <img src="./clients/logo/07.png" alt="test" />
                </div>
                <div className="clients__logo">
                    <img src="./clients/logo/08.png" alt="test" />
                </div>
            </div>

        </div>
    )
}
export default Clients