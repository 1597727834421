import React from 'react';
const Service = (props) => {
    const icon = "global/xdsj/icon.svg#" + props.icon

    return (
        <div className="service__box">
            <svg className="service__box-icon  mb-sm">
                <use xlinkHref={icon}></use>
            </svg>
            <h3 className="heading-2  mb-sm">{props.title}</h3>
            <h2 className="heading-3  mb-sm with-70">
                {props.subtitle}
            </h2>
        </div >
    )
}
export default Service;