import { Fragment } from "react"

const CaseImgList = (props) => {


    const caseImg_info = props.caseImg_info

    let img_class
    let caseImg_banner

    const caseImg_list = caseImg_info.map((item) => {

        img_class = item.isBanner ? `caseImgList__banner` : `caseImgList__list`



        if (!item.isBanner) {
            return (<div className={img_class} key={item.key} >
                <img src={item.cover} />
            </div >)
        }
        else {
            caseImg_banner = <div className={img_class} key={item.key} >
                <img src={item.cover} />
            </div >
        }
    }
    )




    return (
        <Fragment>

            {caseImg_banner}
            <div className="caseImgList" >
                {caseImg_list}
            </div >
        </Fragment>

    )
}
export default CaseImgList