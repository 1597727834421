const Footer = () => {
    return (
        <div className="footer container">
            <div className="footer__QR">
                <img className="mb-md" src="./global/xdsj/videoChennel_QR.png"></img>
                <div className="footer__divider-text heading-3">关注视频号了解最新项目效果</div>
            </div>

            <div className="footer__divider">
                <div className="footer__divider-line"></div>
                <div className="footer__divider-text heading-3"> 欢迎关注相对视觉</div>
                <div className="footer__divider-line"></div>
            </div>
            <div className="footer__container">
                <div className="footer__container-text">公司地址：北京市丰台区南三环西路16号搜宝商务中心2号楼1115</div>
                <div className="footer__container-text">座机：010-67576947</div>
                <div className="footer__container-text">手机：13051017747</div>
                <div className="footer__container-text">京ICP备14016671号-4 </div>
            </div>
        </div>
    )
}
export default Footer