import { Fragment } from "react"
import Service from "../../../Item_components/Service"

const ServicesList = (props) => {

    const serviceList = props.service_info.map((item) =>
        <Service
            title={item.title}
            subtitle={item.subtitle}
            icon={item.icon}
            key={item.key} />
    )
    return (

        <Fragment>
            <section className="service container">
                {serviceList}
            </section>
        </Fragment>
    )

}

export default ServicesList